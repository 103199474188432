<script>
import {
  getCurrentBreakpoint,
} from '@voix/composables/useBreakpoints'
import Blocker from './Blocker'

export default {
  components: {
    Blocker,
  },

  props: {
    containerClasses: {
      type: String,
      default: 'bg-white',
    },
  },

  computed: {
    modalStyle() {
      const currentBreakpoint = getCurrentBreakpoint()
      if (currentBreakpoint !== 'default' || currentBreakpoint === 'sm') {
        return {
          height: '80%',
          width: '70%',
        }
      }
      return {
        height: '90%',
        width: '90%',
      }
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<template>
  <div class="fixed inset-0" style="z-index: 99999">
    <Blocker class="cursor-pointer" @click="close" />

    <svg
      class="w-6 h-6 absolute z-50 top-0 right-0 mt-8 mr-8 text-white cursor-pointer"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      @click="close"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>

    <div
      class="p-8 fixed z-50 rounded shadow-lg overflow-y-scroll"
      :class="containerClasses"
      style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
      :style="modalStyle"
    >
      <slot />
    </div>
  </div>
</template>
